import { Store } from '@store';
import { action, makeAutoObservable } from 'mobx';

import browserHistory from '@/shared/services/browserHistory';

import { HISTORY_ACTIONS } from '@constants/common';

import { getContactDetailsLink } from '@/shared/utils/contactRoutesUtils';

import { BrowserHistoryType, CustomPushData } from './types/commonTypes';
import { MoveToContactProps } from './types/contactTypes';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

export class RouterStore {
  coreStore: Store;
  browserHistory: BrowserHistoryType;
  isTransitionsBlocked: boolean = false;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      setTransitionBlockState: action.bound
    });
    this.coreStore = coreStore;
    this.browserHistory = browserHistory;

    this.browserHistory.listen((location, action) => {
      this.onHistoryBack(action as HISTORY_ACTIONS);
    });
  }

  customPush<S>(data: CustomPushData, state?: S) {
    this.browserHistory.push(data, state);
  }

  moveToContact<S>(props: MoveToContactProps<S>) {
    const { id, tab, contactType, pushProps, } = props;
    let pathname;

    if(tab === CONTACT_DETAILS_TAB.profile){
      pathname = getContactDetailsLink(id, contactType)[tab](props.subTabProps);
    } else {
      pathname = getContactDetailsLink(id, contactType)[tab]();
    }

    this.browserHistory.push({
      ...pushProps,
      pathname,
    });
  }
  onHistoryBack(action: HISTORY_ACTIONS) {
    if(action === HISTORY_ACTIONS.POP && this.coreStore.ModalStore.isSomeModalOpen && !this.isTransitionsBlocked) {
      this.coreStore.ModalStore.closeAllModals();
    }
  }

  setTransitionBlockState (state: boolean) {
    this.isTransitionsBlocked = state;
  }
}
