import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 230px;
`;

export const SelectLabel = styled.div`
  color: ${COLORS.helpText};
  margin: 8px 20px 0 30px;
`;

export const SelectWrapper = styled.div`
  width: 150px;
`;
