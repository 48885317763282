import { endOfDay, isAfter, isBefore } from 'date-fns';

import { FORM_NAMES } from '../components/Notes/components/GeneralFilters/data';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { NOTE_TYPES, SUB_CATEGORIES_COMMUNICATION_TYPES } from '@modules/NotesAndHistory/data';

// TODO: note refactoring
export const getIsDifferFromFilters = (filters: any, note: any) => {
  const result = Object.entries(filters).some((name) => {
    const [ key, value ] = name;
    if(key === FORM_NAMES.noteType && value) {
      if(value === SUB_CATEGORIES_COMMUNICATION_TYPES.all) {
        return false;
      }
      return value !== note.noteType;
    }
    if(key === FORM_NAMES.noteEntity && value) {
      if(value === NOTE_TYPES.all) {
        return false;
      }
      const linkedContactType = note.linkedItem?.linkedContactType;
      if(linkedContactType === LINKED_ITEM_TYPES.account ||
        linkedContactType === LINKED_ITEM_TYPES.policy ||
        linkedContactType === LINKED_ITEM_TYPES.plan) {
        return value !== LINKED_ITEM_TYPES.account &&
          value !== LINKED_ITEM_TYPES.policy &&
          value !== LINKED_ITEM_TYPES.plan;
      }
      return value !== linkedContactType;
    }
    if(key === FORM_NAMES.startDate && value) {
      const result = isAfter(new Date(note.created), new Date(value as string));
      return !result;
    }
    if(key === FORM_NAMES.endDate && value) {
      const result = isBefore(new Date(note.created), endOfDay(new Date(value as string)));
      return !result;
    }
    if(key === FORM_NAMES.noteTitleContent && value) {
      //@ts-ignore
      const result = value.some((text) => {
        const newText = text.trim();
        const noteTextResult = note.noteText?.toUpperCase().indexOf(newText.toUpperCase());
        const noteTitleResult = note.title?.toUpperCase().indexOf(newText.toUpperCase());
        return !noteTextResult || !noteTitleResult;
      });
      return !result;
    }
    if(key === FORM_NAMES.noteTagValue && value) {
      //@ts-ignore
      const result = value.some((text) => {
        const newText = text.trim();
        const result = note?.tags?.some((tag: any) => {
          return tag.value.toUpperCase().indexOf(newText.toUpperCase()) >= 0;
        });
        return result;
      });
      return !result;
    }
  });

  return result;
};