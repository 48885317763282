import {
  endOfMonth,
  endOfWeek,
  format,
  addYears,
  subYears,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';
import { MERIDIAN } from './data';
import { getSeparatedHourAndMinuteValues } from '@/shared/utils/timeConverter';
import assertUnreachable from '@/shared/utils/assertUnreachable';

import { ActivityStatusOptions } from '@/shared/types/activityPopup';
import { CalendarPeriod } from './types';
import { ValueLabelObj } from '@/shared/types/commonTypes';

const formatDate = (date: Date): string => format(date, YEAR_MONTH_DAY);

export const getRange = (period: CalendarPeriod): { start: string, end: string } => {
  const date = new Date();

  switch(period) {
  case CalendarPeriod.month:
    return {
      start: formatDate(startOfMonth(date)),
      end: formatDate(endOfMonth(date)),
    };
  case CalendarPeriod.week:
    return {
      start: formatDate(startOfWeek(date)),
      end: formatDate(endOfWeek(date)),
    };
  case CalendarPeriod.day:
    return {
      start: formatDate(date),
      end: formatDate(date),
    };
  case CalendarPeriod.all:
    return {
      start: formatDate(subYears(date, 100)),
      end: formatDate(addYears(date, 100)),
    };
  default: {
    return assertUnreachable(period, `Unexpected period: ${period}`);
  }
  }
};

export const getMeridian = (timeString: string) => {
  const values = getSeparatedHourAndMinuteValues(timeString);
  if(!values) {
    return null;
  }

  if(Number(values.hours) >= 12) {
    return MERIDIAN.pm;
  }

  return MERIDIAN.am;
};

export const getInitStatusFilter = (statuses: Array<ValueLabelObj> = []) => (
  statuses.filter(status => (
    status.label !== ActivityStatusOptions.Completed && status.label !== ActivityStatusOptions.Cancelled)
  ).map(status => status.value)
);

export const getAllStatusFilter = (statuses: Array<ValueLabelObj>) => {
  return statuses.map(status => status.value);
};
