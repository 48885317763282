import { useCallback } from 'react';
import { useStore } from '@store';

import AddContactToList from '@modules/AddContactToList';
import {
  AddEditFolder,
  AddNewListPopup,
  DeleteListPopup,
  DeleteFolderPopup,
  ExportListContactsPopup,
  MoveListToAnotherFolder,
} from './components';

import { ListsGridItemListType, ListsGridItemType, SaveToParentParams } from '@/shared/types/lists';

import { MODAL_TYPE } from '@constants/modalTypes';
import { NOTIFICATION_TYPES } from '@constants/notifications';

export const useTableActions = (isFolderView: boolean) => {
  const folderStore = useStore().ListsStore.FolderStore;
  const allListStore = useStore().ListsStore.AllListsStore;
  const modalStore = useStore().ModalStore;
  const { profile } = useStore().SettingsStore;
  const { duration } = useStore().SessionStore;

  const store = isFolderView ? folderStore : allListStore;

  const { parent } = store;

  const addEditFolder = useCallback((item?: ListsGridItemType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_LISTS_FOLDER,
      component: AddEditFolder,
      modalProps: {
        saveCallback: store.addUpdateFolder.bind(store),
        parentId: parent?.id || null,
        editData: {
          id: item?.id || null,
          name: item?.name || ''
        }
      }
    });
  }, [modalStore, parent?.id, store]);

  const addNewList = useCallback((item?: ListsGridItemType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_LISTS_ITEM,
      component: AddNewListPopup,
      modalProps: {
        currentUser: profile,
        parentId: parent?.id || null,
        saveCallback: store.addNewList.bind(store),
      }
    });
  }, [modalStore, parent?.id, profile, store]);

  const addContactsToList = useCallback((item: ListsGridItemType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CONTACT_TO_LIST,
      modalProps: {
        listId: item.id,
        listName: item.name,
        onSave: store.addContactsToList.bind(store)
      },
      component: AddContactToList,
    });
  }, [modalStore, store]);

  const removeLists = useCallback((arg: number | Array<number>) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.REMOVE_LISTS,
      modalProps: {
        ids: Array.isArray(arg) ? arg : [arg],
        onSave: store.deleteLists.bind(store)
      },
      component: DeleteListPopup,
    });
  }, [modalStore, store]);

  const deleteFolder = useCallback((item: ListsGridItemType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_LIST_FOLDER,
      modalProps: {
        item,
        onSave: store.deleteFolder.bind(store)
      },
      component: DeleteFolderPopup,
    });
  }, [modalStore, store]);

  const showNotification = useCallback((data: ListsGridItemListType) => {
    allListStore.listNotificationHelper.download({
      autoHideDuration: duration,
      otherEntityName: `list «${data.name}»`,
      status: NOTIFICATION_TYPES.info,
      uniqueKey: data.id,
    });
  }, [allListStore, duration]);

  const onSuccessDownloadList = useCallback((data: ListsGridItemListType) => {
    const listKey = `info download list «${data.name}» ${data.id}`;
    const isExist = allListStore.listNotificationHelper.notificationsStore.isNotificationCreated(listKey);
    if(isExist) {
      allListStore.listNotificationHelper.notificationsStore.removeNotification(listKey);  
    }
    allListStore.listNotificationHelper.download({
      otherEntityName: `list «${data.name}»`,
      status: NOTIFICATION_TYPES.success,
    });
  }, [allListStore]);

  const onErrorDownloadList = useCallback((data: ListsGridItemListType) => {
    const listKey = `info download list «${data.name}» ${data.id}`;
    const isExist = allListStore.listNotificationHelper.notificationsStore.isNotificationCreated(listKey);
    if(isExist) {
      allListStore.listNotificationHelper.notificationsStore.removeNotification(listKey);
      allListStore.listNotificationHelper.download({
        otherEntityName: `list «${data.name}»`,
        status: NOTIFICATION_TYPES.error,
      });  
    }
  }, [allListStore]);

  const onExportList = useCallback((item: ListsGridItemListType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.EXPORT_LIST_CONTACTS,
      modalProps: {
        item,
        onErrorDownloadList: (data: ListsGridItemListType) => onErrorDownloadList(data),
        onSave: (data: ListsGridItemListType) => showNotification(data),
        onSuccessDownloadList: (data: ListsGridItemListType) => onSuccessDownloadList(data),
        profileLogoutTime: duration
      },
      component: ExportListContactsPopup,
    });
  }, [modalStore, showNotification, onErrorDownloadList, onSuccessDownloadList, duration]);

  const moveList = useCallback((item: ListsGridItemListType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.REMOVE_LISTS,
      modalProps: {
        listItem: item,
        onSave: store.moveToAnotherFolderAndCreateFolder.bind(store)
      },
      component: MoveListToAnotherFolder,
    });
  }, [modalStore, store]);

  const onDropList = useCallback((data: SaveToParentParams) => {
    store.moveToAnotherFolder(data);
  },[store]);

  return {
    addContactsToList,
    addEditFolder,
    addNewList,
    deleteFolder,
    moveList,
    onDropList,
    onExportList,
    removeLists,
  };
};
