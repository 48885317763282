import { AxiosResponse } from 'axios';

import {
  CATEGORIES, DUE_DATE_TYPES,
  TO_DOS_LINKED_ITEMS_IDS,
  OFFICIAL_REQUEST_SUBCATEGORIES,
  PRIORITIES,
  STAGES,
  TODOS_FILTER_NAMES
} from '@constants/todosData';
import { LINKED_ITEM_TYPES, LINKED_ITEM_DATA_NAMES } from '@constants/linkedItem';

import {
  BackendCommonResponse,
  BackendGridResponse,
  Client,
  IdType,
  LinkedItem,
  Permission,
  ValueLabelObj,
  ValueOf,
} from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';
import { NoteItem } from '@/shared/types/note';

export type PrioritiesType = ValueOf<typeof PRIORITIES>
export type CategoriesType = ValueOf<typeof CATEGORIES>
export type RequestType = ValueOf<typeof OFFICIAL_REQUEST_SUBCATEGORIES>
export type StagesType = ValueOf<typeof STAGES>
export type DueDateTypes = ValueOf<typeof DUE_DATE_TYPES>

export type TodoFormFields = {
  assignedBy: IdType
  createdDate: string | null
  assignId: IdType
  category: CategoriesType | RequestType
  createdAt: string
  createFUTask: boolean
  createFUTaskForAssignerToMonitor: boolean
  description: string | null
  dueDate: string | null
  dueDateType: DueDateTypes
  endDate: string | null
  frequency: string
  id: IdType
  isNew: boolean
  linkedContactId: IdType | null
  linkedItemId: IdType
  linkedItemType: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  priority: PrioritiesType | null
  request: RequestType
  requirring: boolean
  reviewDate: string | null
  stage: StagesType | null
  taskNotes: Array<NoteItem>
  type: TASK_TYPE
  updatedCount: number | null
  withRecurring?: boolean
};

export enum TASK_TYPE {
  OTHER = 'Other',
  CLIENT_SPECIFIC = 'Client-Specific'
}

export type BackendTodoFormFields = Omit<TodoFormFields, 'requirring' | 'id'> & {
  accountId: IdType | null, 
  id?: IdType,
  opportunityId: IdType | null, 
  requirring: string
  salesPipelineId: IdType | null,
  taskId: IdType | null,
}

export type TodoGridFormField = Pick<
  TodoFormFields,
  'id' | 'dueDate' | 'priority' | 'stage' | 'updatedCount' | 'createdAt' | 'reviewDate'
>

export type TodoItem = TodoFormFields & {
  assignedByData: Client
  contactData: LinkedItem
  clientData: Client
  clientId: IdType
  createdBy: IdType
  createdByData: Client
  flagged: boolean
  linkedItemData: LinkedItem
  linkedItemId: IdType
} & Permission;

export type SchemeDataName = ValueOf<Pick<
  typeof LINKED_ITEM_DATA_NAMES,
  'CONTACT_DATA' | 'ACCOUNT_DATA' | 'OPPORTUNITY_DATA' | 'PLAN_DATA' |
  'POLICY_DATA' | 'SALES_PIPELINE_DATA' | 'TASK_DATA'>
>

type SchemeType = ValueOf<Pick<
  typeof LINKED_ITEM_TYPES,
  'contact' | 'account' | 'opportunity' | 'plan' | 'policy' | 'salesCycle' | 'task'>
>

export type SchemeIdName = ValueOf<typeof TO_DOS_LINKED_ITEMS_IDS>

export type LinkedItemSchemeType = {
  type: SchemeType,
  id: SchemeIdName,
  dataName: SchemeDataName,
}

export type LinkedItemsSchemeType = SchemeDataName;

export type LinkedContactType = {
  linkedContactType: LinkedItemSchemeType['type']
};

export type LinkedTask = LinkedContact & {
  assignId: string
  clientData: LinkedContact
  contactData: LinkedContact
  dueDate: string
  linkedContactType: ValueOf<Pick<typeof LINKED_ITEM_TYPES, 'task'>>
  // eslint-disable-next-line no-restricted-globals
  name: string
  stage: StagesType
};

export type DeleteTodoItem = {
  id: IdType,
  withRecurring: boolean,
}

export type DeleteTodoParams = Array<DeleteTodoItem>

export type TodoPopupDeleteCallback = (data: DeleteTodoParams) => void
export type TodoPopupSaveCallback = (data: BackendTodoFormFields) => void
export type TodoPopupUpdateCallback = () => void
export type TodoPopupCloseActionCallback = () => void

export type TodoDeleteConfirmationPopupProps = {
  id: IdType,
  isRequirring: boolean
  onCloseAction?: TodoPopupCloseActionCallback
  onConfirm: TodoPopupDeleteCallback
}

export type OnAddEditTaskCallback = (
  id?: IdType,
  isCloneTask?: boolean,
  previousCopyData?: Partial<TodoFormFields>
) => void

export type TodoEditConfirmationPopupProps = {
  formData: BackendTodoFormFields
  onCloseAction?: TodoPopupCloseActionCallback
  onConfirm: TodoPopupSaveCallback
}

export type TodoPopupProps = {
  id?: TodoFormFields['id']
  isLinkedItem?: boolean
  initLinkedContact: LinkedItem | null
  isCloneTask?: boolean
  previousCopyData?: TodoFormFields | {}
  deleteCallback: TodoPopupDeleteCallback
  onCloseAction?: TodoPopupCloseActionCallback
  saveCallback: TodoPopupSaveCallback
  updateCallback?: () => void
}

export type MassDeleteTodoPopupProps = {
  deleteData: DeleteTodoParams
  onConfirm: (params: DeleteTodoParams) => void
}

export type OverdueFilter = {
  type: string,
  params?: {
    [x: string]: string | boolean;
  }
}

export type TodoItemNotesAndHistory = Pick<
  TodoFormFields,
  'dueDate' | 'priority' | 'stage' | 'category' | 'name' | 'id'
> & Pick<TodoItem, 'clientData'>

export type TodoPopupSettings = {
  priority: Array<ValueLabelObj>
  stage: Array<ValueLabelObj>
  category: Array<ValueLabelObj>
  recurringFrequency: Array<ValueLabelObj>
};

export type TaskPopupSettingsResponseData = {
  priority: Array<string>
  stage: Array<string>
  category: Array<string>
  recurringFrequency: Array<string>
};

export type TodoData = {
  [key: string]: TodoItem
} & {
  filterData: FilterDataResponse
};

export type FilterDataResponse = {
  clients: Array<Client>
  category: Array<string>
  stage: Array<string>
};

export type TodoPopupSettingsResponse = AxiosResponse<BackendCommonResponse<TaskPopupSettingsResponseData>>;
export type GridResponse = AxiosResponse<BackendGridResponse<TodoData>>;

export type OfficialRequestSubcategory = ValueOf<typeof OFFICIAL_REQUEST_SUBCATEGORIES>; 

export type CategoryFilter = {
  [TODOS_FILTER_NAMES.category]?: string | number,
  [TODOS_FILTER_NAMES.request]?: string | number,
};
