import { differenceInDays, endOfDay, format, isBefore } from 'date-fns';

import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';
import { parseFormatedUTCDateString } from '@/shared/utils/parseFormatedUTCDateString';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { ContactActivity } from '@services/store/contactDetailsActivitiesStore/types';

// need to include current day to 'days before activity' counter
const ONE_DAY = 1;

export const getActivityStartDateFields = (activity: ContactActivity) => {

  const { allDay, startDate, startTime } = activity;

  let UTCActivityStartDate = new Date();
  if(!yesNoToBoolean(allDay)) {
    UTCActivityStartDate = parseFormatedUTCDateString(`${startDate} ${startTime}`);
  } else {
    UTCActivityStartDate = new Date(`${startDate} ${startTime}`);
  }

  const isBeforeCurrentDate = isBefore(UTCActivityStartDate, endOfDay(new Date()));
  const activityStartDate = isBeforeCurrentDate ? new Date() : UTCActivityStartDate;
  const activityDate = format(activityStartDate, MONTH_DAY_YEAR_BACKSLASH);

  const numberOfDaysBeforeActivity = differenceInDays(new Date(activityDate), new Date()) + ONE_DAY;
  const typeName = numberOfDaysBeforeActivity > 1 ? 'days' : 'day';
  
  return {
    activityDate,
    numberOfDaysBeforeActivity: isBeforeCurrentDate ? 0 : numberOfDaysBeforeActivity,
    typeName
  };
};