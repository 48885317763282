import React, { useCallback } from 'react';
import { Popover } from '@material-ui/core';

import { useStore } from '@store';

import { ANCHOR_ORIGIN_PROPS, PAPER_PROPS, TRANSFORM_ORIGIN_PROPS } from './data';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { MODAL_TYPE } from '@constants/modalTypes';

import LimitAccessPopup from '@modules/LimitAccessPopup';
import UserItem from '@components/NewUserItem';

import { TextWrapper, UserItemWrapper } from './styles';

import { RecentContactsListsProps } from './types';
import { LinkedContact } from '@/shared/types/linkedContact';


export const RecentContactsLists = ({
  anchorEl,
  contactsList,
  onClose,
}: RecentContactsListsProps) => {

  const modalStore = useStore().ModalStore;

  const showLimitedAccessPopup = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [modalStore]);

  const routerStore = useStore().RouterStore;

  const goToContactOverview = useCallback((contact: LinkedContact) => {
    routerStore.moveToContact({
      id: contact.id,
      contactType: contact.type,
      tab: CONTACT_DETAILS_TAB.overview,
    });
    onClose();
  }, [onClose, routerStore]);

  const onUserItemClick = useCallback((contact: LinkedContact) => {
    if(contact.editAllow && Number(contact.editAllow) === 1) {
      goToContactOverview(contact);
    } else {
      showLimitedAccessPopup();
    }
  }, [goToContactOverview, showLimitedAccessPopup]);

  return (
    <Popover
      anchorEl={ anchorEl }
      anchorOrigin={ ANCHOR_ORIGIN_PROPS }
      onClose={ onClose }
      open={ true }
      PaperProps={ PAPER_PROPS }
      transformOrigin={ TRANSFORM_ORIGIN_PROPS }
    >
      { contactsList && contactsList.length > 0 && contactsList.map((contact) => (
        <UserItemWrapper
          key={ contact.id }
          onClick={ () => onUserItemClick(contact) }
        >
          <UserItem
            containerStyle={ {
              paddingBottom: 10
            } }
            user={ contact }
          />
        </UserItemWrapper>
      ))
      }
      { !contactsList || contactsList.length === 0 &&
        <TextWrapper>No contacts found</TextWrapper>
      }
    </Popover>
  );
};
