import { parse, intervalToDuration } from 'date-fns';

import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { getConvertedStartEndDatesToDST } from '@/shared/utils/getConvertedStartEndDatesToDST';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { EVENT_TYPES_BACKEND } from '@constants/colorsByEventTypes';
import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';


const calculateStartAndEnd = (item, timezone) => {
  const convertedStartEndDatesToDST = getConvertedStartEndDatesToDST({
    ...item,
    isAllDay: yesNoToBoolean(item.allDay)
  }, timezone);

  const startDateBasedOnForDay = parse(
    `${convertedStartEndDatesToDST.startDate} ${convertedStartEndDatesToDST.startTime}`,
    YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
    new Date()
  );

  const endDateBasedOnForDay = parse(
    `${convertedStartEndDatesToDST.endDate} ${convertedStartEndDatesToDST.endTime}`,
    YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
    new Date()
  );

  return {
    start: startDateBasedOnForDay,
    end: endDateBasedOnForDay
  };
};


const getStartAndEnd = (item, timezone) => {
  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime,
    isAllDay: yesNoToBoolean(item.allDay)
  });

  if(!yesNoToBoolean(item.recurring)){
    const convertedStartEndDatesToDST = getConvertedStartEndDatesToDST({
      startDate,
      startTime,
      endDate,
      endTime,
      isAllDay: yesNoToBoolean(item.allDay)
    }, timezone);
  
    return {
      end: parse(
        `${convertedStartEndDatesToDST.endDate} ${convertedStartEndDatesToDST.endTime}`,
        YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
        new Date()
      ),
      start: parse(
        `${convertedStartEndDatesToDST.startDate} ${convertedStartEndDatesToDST.startTime}`,
        YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
        new Date()
      ),
    };
  }

  return calculateStartAndEnd({
    startDate,
    endDate,
    forDay: item.forDay,
    endTime,
    startTime,
  }, timezone);
};

const getAllDay = item => {
  const booleanAllDay = yesNoToBoolean(item.allDay);
  if(booleanAllDay) {
    return booleanAllDay;
  }

  let allDay = false;

  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime
  });

  try {
    const { days, years, months } = intervalToDuration({
      end: parse(`${endDate} ${endTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
      start: parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
    });

    if(days >= 1 || months >= 1 || years >= 1) {
      allDay = true;
    }
  } catch (error) {
    console.error(error);
  }

  return allDay;
};

const findStatusLabelById = (arr, id) => {
  if(!Array.isArray(arr)) {
    return null;
  }
  const found = arr.find(item => item.id === id);
  return found ? found.option : null;
};

export const calendarNormalizer = (resp, statusData, timezone) => {
  if(!resp) {
    return [];
  }

  const result = resp.map(item => {
    let { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
      startDate: item.startDate,
      startTime: item.startTime,
      endDate: item.endDate,
      endTime: item.endTime,
      isAllDay: yesNoToBoolean(item.allDay)
    });
  
    return {
      ...getStartAndEnd(item, timezone),
      allDay: yesNoToBoolean(item.allDay),
      title: item.name,
      resource: {
        allDay: yesNoToBoolean(item.allDay),
        clients: item.clients,
        description: item.description,
        editAllow: item.editAllow,
        endDate,
        endTime,
        forDay: item?.forDay,
        id: item.id,
        isAllDayGrid: getAllDay(item),
        name: item.name,
        primaryClientId: item.primaryClientId,
        primaryContactId: item.primaryContactId,
        recurring: yesNoToBoolean(item.recurring),
        recurringEndDate: item.recurringEndDate,
        recurringFrequency: item.recurringFrequency,
        startDate,
        startTime,
        statusId: item.statusId,
        statusLabel: findStatusLabelById(statusData, item.statusId),
        subCategoryId: item.subCategoryId,
        isEditable: item.type !== EVENT_TYPES_BACKEND.holiday && item.editAllow,
        type: typeConverter({
          isBackend: true,
          type: item.type,
        }),
      }
    };
  });

  return result;
};
