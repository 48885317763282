import React from 'react';
import { GenerateMessageProps } from './types';
import { NOTIFICATION_MESSAGE_ACTIONS, NOTIFICATION_TYPES } from '@constants/notifications';

export const generateArrayOfLines = ({
  status,
  entity,
  action,
  countOfEntities,
}: GenerateMessageProps): Array<string> => {
  let messageType: string;
  let message: string[] = [];

  let processedEntity = countOfEntities && countOfEntities > 1 ? `${countOfEntities} ${entity}(s)` : entity;

  switch (action) {
  case NOTIFICATION_MESSAGE_ACTIONS.create:
    messageType = status === NOTIFICATION_TYPES.success
      ? `The ${processedEntity} has been created.`
      : `The creation of ${processedEntity} has failed.`;
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.update:
    messageType = status === NOTIFICATION_TYPES.success
      ? `The ${processedEntity} has been updated.`
      : `The updating of ${processedEntity} has not been saved.`;
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.delete:
    messageType = status === NOTIFICATION_TYPES.success
      ? `The ${processedEntity} has been deleted.`
      : `The deleting of ${processedEntity} has failed.`;
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.remove: // Handling remove action
    messageType = status === NOTIFICATION_TYPES.success
      ? `The ${processedEntity} has been removed successfully to the Trash`
      : `The removal of ${processedEntity} to the Trash has failed.`;
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.load:
    messageType = status === NOTIFICATION_TYPES.success
      ? 'The loading is success.'
      : 'The loading is failed. Please try again.';
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.restore:
    messageType = status === NOTIFICATION_TYPES.success
      ? `The ${processedEntity} has been restored successfully.`
      : `The restore of ${processedEntity} has failed.`;
    break;
  case NOTIFICATION_MESSAGE_ACTIONS.download: {
    messageType = '';
    if(status === NOTIFICATION_TYPES.info) {
      messageType = `A ${processedEntity} download is started.`;
    } else if(status === NOTIFICATION_TYPES.success) {
      messageType = `A ${processedEntity} download successfully.`;
    } else if(status === NOTIFICATION_TYPES.error) {
      messageType = `A ${processedEntity} download has failed.`;
    }
    break;
  }
  default:
    throw new Error('Invalid action type');
  }

  if (status === NOTIFICATION_TYPES.error && action !== NOTIFICATION_MESSAGE_ACTIONS.load) {
    message.push(messageType, 'Please try again.');
  } else {
    message.push(messageType);
  }

  return message;
};

export const convertArrayOfStringToHtmlLines = (message: Array<string>) => {
  if (message.length === 1) {
    return message[0];
  }

  return (
    <>
      {
        message.map((line, index) => (
          <React.Fragment key={ index }>
            <span>{line}</span>
            <br />
          </React.Fragment>
        ))
      }
    </>
  );
};

export const generateMessage = ({
  status,
  entity,
  action,
  countOfEntities,
}: GenerateMessageProps): React.ReactNode => {
  const message = generateArrayOfLines({
    status,
    entity,
    action,
    countOfEntities,
  });

  return convertArrayOfStringToHtmlLines(message);
};
