import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    width: 148px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MeridianWrapper = styled.div`
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  padding: 6px 7px;
  border-radius: 4px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ? `${$marginBottom}px` : 0 };
  cursor: pointer;

  color: ${props => (
    props.$isActive
      ? COLORS.white
      : COLORS.primaryText
  ) };

  background-color: ${props => (
    props.$isActive
      ? COLORS.orange
      : 'transparent'
  ) };

  &:hover {
    background-color: ${props => (
    props.$isActive
      ? COLORS.orange
      : COLORS.yellow
  ) };

    color: ${props => (
    props.$isActive
      ? COLORS.white
      : COLORS.white
  ) };

  }
`;
