import { NotificationError, NotificationSuccess, NotificationLoading } from '@/assets';

import { COLORS } from '@constants/colors';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { NotificationConfigItem } from './types';
import { NotificationTypesType } from '@/shared/types/notifications';

export const NOTIFICATION_ITEM_CONFIG: Record<NotificationTypesType, NotificationConfigItem> = {
  [NOTIFICATION_TYPES.default]: {
    $bgColor: 'transparent',
    $fontColor: COLORS.primaryText,
    $iconColor: 'transparent',
    $indicatorColor: 'transparent',
    icon: NotificationSuccess
  },
  [NOTIFICATION_TYPES.error]: {
    $bgColor: COLORS.redBg,
    $fontColor: COLORS.primaryText,
    $iconColor: COLORS.red,
    $indicatorColor: COLORS.red,
    icon: NotificationError
  },
  [NOTIFICATION_TYPES.info]: {
    $bgColor: COLORS.lightBlue,
    $fontColor: COLORS.primaryText,
    $iconColor: 'transparent',
    $indicatorColor: COLORS.blue,
    icon: NotificationLoading
  },
  [NOTIFICATION_TYPES.success]: {
    $bgColor: COLORS.notificationGreenBg,
    $fontColor: COLORS.primaryText,
    $iconColor: COLORS.green,
    $indicatorColor: COLORS.green,
    icon: NotificationSuccess
  },
  [NOTIFICATION_TYPES.warning]: {
    $bgColor: 'transparent',
    $fontColor: COLORS.primaryText,
    $iconColor: 'transparent',
    $indicatorColor: 'transparent',
    icon: NotificationSuccess
  },
};
