import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import FetchAutoComplete from '@modules/FetchAutoComplete';
import UserItem from '@components/NewUserItem';

import { StyledTextField } from './styles';

import { getFullName, getParamsToSearchContactNamePhoneEmail } from '@/shared/utils/fetchAutocompleteUtils';
import { searchContactByNamePhoneEmail } from '@services/api/common/common';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { CONTACT_TYPES } from '@constants/common';
import { LinkedContact } from '@/shared/types/linkedContact';



const contactResponseConverter = (response: any) => {
  const data = response?.data?.data?.data;
  return data ? Object.values(data) : [];
};


export const ContactsSearch = observer(() => {
  const routerStore = useStore().RouterStore;

  const [inputValue, setInputValue] = useState<string>('');


  const onChange = useCallback((data: LinkedContact) => {
    setInputValue('');

    if(data) {
      routerStore.moveToContact({
        id: data?.id,
        contactType: data?.type,
        tab: CONTACT_DETAILS_TAB.overview,
      });
    }
  }, [routerStore, setInputValue]);

  const searchValidation = useMemo(() => (value: string) => (
    value.replace(/^@/, '').replace(/^\(/, '').replace(/^\)/, '').replace(/^\./, '')
  ), []);

  return (
    <FetchAutoComplete
      AutocompleteProps={ {
        clearOnBlur: true,
        blurOnSelect: true,
        getOptionLabel: (option: any) => option.fullName || getFullName(option) || 'label',
        filterOptions: (options: any) => options,
        renderOption: (option: any) => {
          if(option.type === CONTACT_TYPES.HOUSEHOLD && !option.householderContacts ||
            option.type === CONTACT_TYPES.INDIVIDUAL && (!option.firstName || !option.lastName) ||
            option.type === CONTACT_TYPES.BUSINESS && !option.occupationCompany && !option.fullName) {
            return null;
          }
          return (
            <UserItem
              containerStyle={ {
                paddingLeft: '10px',
              } }
              user={ option }
              withCoreClassification={ true }
            />);
        },
        renderInput: (params) => (
          <StyledTextField
            { ...params }
            InputProps={ {
              ...params.InputProps,
            } }
            placeholder={ 'Type to search Contacts, Phones, Emails...' }
            variant='outlined'
          />
        )
      } }
      FetchProps={ {
        getParams: getParamsToSearchContactNamePhoneEmail,
        requestSender: searchContactByNamePhoneEmail,
        responseConverter: contactResponseConverter
      } }
      onChange={ onChange }
      outSideInputValue={ inputValue }
      searchValidation={ searchValidation }
      setOutSideInputValue={ setInputValue }
    />
  );
});
