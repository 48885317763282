import { isBefore, isAfter, addHours, format } from 'date-fns';

import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import { StartEndDateItem, SummerAndWinterTime, Timezone } from '@/shared/types/commonTypes';


export const getConvertedStartEndDatesToDST = (
  item: StartEndDateItem,
  timezone: Timezone,
  numberOfAddedHours: number = 1
) => {
  const { startDate, startTime, endDate, endTime, isAllDay } = item;

  const resultDatesObject = {
    startDate,
    startTime,
    endDate,
    endTime
  };

  // TODO: fix VA-2020
  // Object.values(timezone).reduce((prev: SummerAndWinterTime, curr: SummerAndWinterTime) => {
  //   const start = new Date(`${startDate} ${startTime}`);
  //   const end = new Date(`${endDate} ${endTime}`);

  //   if(prev.winterTimeChange && curr.summerTimeChange &&
  //     isBefore(new Date(prev.winterTimeChange), start) &&
  //     isAfter(new Date(curr.summerTimeChange), start) &&
  //     !isAllDay
  //   ) {
  //     const startDST = addHours(start, numberOfAddedHours);
  //     const startDateString = format(startDST, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

  //     const endDST = addHours(end, numberOfAddedHours);
  //     const endDateString = format(endDST, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

  //     resultDatesObject.startDate = startDateString[0];
  //     resultDatesObject.startTime = startDateString[1];
  //     resultDatesObject.endDate = endDateString[0];
  //     resultDatesObject.endTime = endDateString[1];
  //   }
  //   return curr;
  // },
  // {} as SummerAndWinterTime,
  // );

  return resultDatesObject;
};
