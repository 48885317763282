import * as yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { validationMessages } from '@constants/validationMessages';

const phoneUtil = PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export const isPhoneValid = (phone: string) => {
  if(getIsOnlyCountryCode(phone)) {
    return true;
  }
  return isValidNumber(phone);
};

export const getInternationalFormatNumber = (number?: string) => {
  if(number && isValidNumber(number)) {
    return phoneUtil.format(phoneUtil.parseAndKeepRawInput(number as any), PNF.NATIONAL);
  }
  return number;
};

export const isValidNumber = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const getIsOnlyCountryCode = (phoneNumber: string) => {
  try {
    // If there is an error, it means the user entered only a code or an incomplete code
    // If there is no error, BUT false is returned, it means the user did't enter a full phone number
    return !(phoneUtil.parse(phoneNumber, 'US'));
  } catch (error) {
    return true;
  }
};

export const phoneValidation = yup.string()
  .nullable()
  .notRequired()
  .test('error', validationMessages.invalidPhone, function (value) {
    return isPhoneValid(value || '');
  });

export const requiredPhoneValidation = yup.string()
  .required(validationMessages.invalidPhone)
  .test('error', validationMessages.invalidPhone, function (value) {
    return isPhoneValid(value || '');
  });

