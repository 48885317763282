import { useRef, useCallback, RefObject } from 'react';
import { DebouncedFunc, throttle } from 'lodash';

type Props = {
  onIntersection: () => void,
  delay: number
  marginFromBottom?: number
}

export type UseLazyLoadingReturn<T> = [
  onScroll: DebouncedFunc<() => void>,
  containerRef: RefObject<T>
];

export const useLazyLoading = <T extends HTMLElement>({
  delay = 1000,
  marginFromBottom = 10,
  onIntersection,
}: Props): UseLazyLoadingReturn<T> => {
  const containerRef = useRef<T>(null);

  const onScroll = useCallback(
    throttle(() => {
      if(containerRef && containerRef.current) {
        const containerScrollTop = containerRef.current.scrollTop;
        const containerHeight = containerRef.current.clientHeight;
        const scrollHeight = containerRef.current.scrollHeight;

        const bottomPoint = scrollHeight - containerScrollTop - containerHeight - marginFromBottom;
        if (bottomPoint <= 0 && containerHeight > 0) {
          onIntersection();
        }  
      }
    }, delay),
    [onIntersection, containerRef, marginFromBottom, delay]
  );

  return [onScroll, containerRef];
};
