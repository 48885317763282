import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';

import { NotesAndHistoryStore } from '@modules/NotesAndHistory/notesAndHistoryStore';

import { deleteTodo, saveTodo, updateTodo } from '@services/api/addAndEditTask/addAndEditTask';
import { getContactByIdWithNextAppointmentDate } from '@/shared/services/api/contacts/contacts';
import { getGridForContact } from '@services/api/todos/todos';
import CommonTableStore from '@services/store/commonTableStore';

import { CONTACT_DETAILS_SIDE_BAR_PANEL_TAB } from '@modules/ContactDetailsSideBarPanel/data';
import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';
import { SortDirectionNames } from '@constants/common';
import { TODOS_FILTER_NAMES } from '@constants/todosData';

import { contactNormalizer, spouseNormalizer } from './normalizer';
import { getFilterParams, getSortParams } from '@/shared/utils/filterUtils';
import { todoItemNormalizer } from '@/shared/utils/toDosNormalizers';
import { todoItemsResponseNormalizer } from '@/shared/utils/todoItemsResponseNormalizer';

import {
  BackendTodoFormFields,
  DeleteTodoParams,
  GridResponse,
  TodoItem,
} from '@/shared/types/todos';
import { ClientData } from '@/shared/types/contactOverview';
import { ContactItem, ContactRelatedItem } from '@/shared/types/contact';
import { ContactsData } from '@/shared/types/contactsData';
import { GetContactByIdWithNextAppointmentDateResponse, NextAppointment } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { NoteTagItem } from '@/shared/types/tags';


class ContactDetailsSideBarPanelLocalStore {
  adviserData: ClientData | null = null;
  contact: ContactItem | null = null;
  contactId: IdType | null = null;
  contactsDetails: ContactsData | null = null;
  contactSpouse: ContactRelatedItem | null = null;
  currentTab: string = CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails;
  isLoading: boolean = false;
  nextAppointment: NextAppointment | null = null;
  notesAndHistoryStore: NotesAndHistoryStore;
  predefinedTags: Array<NoteTagItem> = [];
  table: CommonTableStore<TodoItem>;
  todoTableActivePage: number = 1;
  useFormMethods: any = {};

  onPageChangeReaction: IReactionDisposer;

  onRefreshToDoTable: () => void = () => {};

  constructor() {
    makeAutoObservable(this, {
      getToDos: flow.bound,
      getNotes: flow.bound,
      init: action.bound,
      onRemove: flow.bound,
      onUpdate: flow.bound,
      reset: action.bound,
      setCurrentTab: action.bound,
      setIsLoading: action.bound,
      setTodoTableActivePage: action.bound,
    });

    this.table = new CommonTableStore<TodoItem>();
    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.notesAndHistoryStore = new NotesAndHistoryStore();
  }

  init(contactId: IdType, useFormMethods: any, onRefreshToDoTable?: () => void) {
    this.contactId = contactId;
    this.useFormMethods = useFormMethods;
    this.getContactByIdWithNextAppointmentDate(this.contactId);
    this.onRefreshToDoTable = onRefreshToDoTable ?? (() => {});
  }

  *getNotes() {
    this.setIsLoading(true);

    this.notesAndHistoryStore.resetState();
    this.notesAndHistoryStore.resetStore();
    try {
      yield this.notesAndHistoryStore.initStore({
        //@ts-ignore
        contact: this.contact,
        entityName: CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.noteContactId,
        useFormMethods: this.useFormMethods
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  }

  *getContactByIdWithNextAppointmentDate(id: IdType) {
    this.setIsLoading(true);
    try{
      const response: GetContactByIdWithNextAppointmentDateResponse =
        yield getContactByIdWithNextAppointmentDate({ id });
      const data = response.data.data['0'];
      
      this.contact = contactNormalizer(data);
      this.contactSpouse = spouseNormalizer(data);

      this.contactsDetails = this.contact?.contacts || [];
      this.nextAppointment = !Array.isArray(data.nextAppointmentDateData) ? data.nextAppointmentDateData : null;
      this.adviserData = {
        primaryAdviser: data.officePrimaryAdviserData,
        secondaryAdviser: data.officeSecondaryAdviserData,
      };
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    } 
  }

  *getToDos() {
    this.setIsLoading(true);
    this.table.items = [];
    this.todoTableActivePage = 1;
    try {
      yield this.getToDosWithoutLoading();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  }

  *getToDosWithoutLoading() {
    const filters = {
      [TODOS_FILTER_NAMES.linkedContactId]: this.contactId,
    };
    const response: GridResponse = yield getGridForContact({
      ...getFilterParams(filters),
      ...getSortParams(TODOS_FILTER_NAMES.createdDate, SortDirectionNames.Desc),
      page: this.todoTableActivePage
    });

    const normalized = todoItemsResponseNormalizer(response.data.data.data);
    this.table.items = this.table.items.concat(normalized.items.map((item: TodoItem) => todoItemNormalizer(item)));
    this.table.setPaginationData(response.data.data);
  }

  *onUpdate(data: BackendTodoFormFields) {
    this.setIsLoading(true);
    try {
      if(data.id) {
        yield updateTodo(data);
      } else {
        yield saveTodo(data);
      }
      yield this.getToDos();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
      this.onRefreshToDoTable();
    }
  }

  *onRemove(params: DeleteTodoParams) {
    this.setIsLoading(true);
    try {
      yield deleteTodo(params);
      yield this.getToDos();
      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
      this.onRefreshToDoTable();
    }
  }

  createOnPageChangeReaction() {
    return reaction(
      () => this.todoTableActivePage,
      () => {
        if(this.todoTableActivePage !== 1) {
          this.getToDosWithoutLoading();
        }
      },
    );
  }

  setTodoTableActivePage() {
    if(this.todoTableActivePage < this.table.totalPages) {
      this.todoTableActivePage = this.todoTableActivePage + 1;
    }
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCurrentTab(newTabValue: string) {
    this.currentTab = newTabValue;
  }

  reset() {
    this.adviserData = null;
    this.contact = null;
    this.contactId = null;
    this.contactsDetails = null;
    this.contactSpouse = null;
    this.currentTab = CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails;
    this.nextAppointment = null;
    this.predefinedTags = [];
    this.table.resetTable();
    this.todoTableActivePage = 1;
  }
}

export default ContactDetailsSideBarPanelLocalStore;
