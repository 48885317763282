import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getActiveClientByName } from '@services/api/common/common';
import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { LABEL_WIDTH } from '@modules/TodoPopup/data';
import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import {
  ASSIGNED_TASK_INSTRUCTIONS_INPUT_PROPS,
  ASSIGNED_TASK_INSTRUCTIONS_TEXT_FIELD_PROPS, 
  LISTBOX_PROPS
} from './data';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';

import { getAppointmentDateLabel } from '@/shared/utils/getApptDateLabel';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getUTCFormattedString } from '@/shared/utils/getUTCFormattedString';

import { DateButtons } from '@modules/TodoPopup/components';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import NextApptDate from '@modules/NextApptDate';

import {
  CreatedDateWrapper,
  DateWrapper,
  LeftColumnWrapper,
  RightColumnWrapper
} from '../styles';

import { AssignedDetailsProps } from './types';


export const AssignedDetails = observer(({
  isAssignedToEqualCurrentUser
}: AssignedDetailsProps) => {

  const { control, setValue, resetField } = useFormContext();
  const [ assignedBy, createFUTask, createFUTaskForAssignerToMonitor, assignId ] = useWatch({
    control,
    name: [
      TO_DOS_FIELDS_NAMES.assignedBy,
      TO_DOS_FIELDS_NAMES.createFUTask,
      TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor,
      TO_DOS_FIELDS_NAMES.assignId,
    ]
  });

  const {
    id,
    isAssignedDetailsDisabled,
    isGlobalDisabled,
    createdByData,
    createdDate,
    assignedByData,
    nextAppointmentDate
  } = useTodoPopupLocalStore();

  const disabledReviewDate = !createFUTask ? true : false;
  const disabledReviewDateToMonitor = !createFUTaskForAssignerToMonitor ? true : false;

  const formattedCreationDate = createdDate && id ? 
    getUTCFormattedString(createdDate, MONTH_DAY_YEAR_BACKSLASH) : format(new Date(), MONTH_DAY_YEAR_BACKSLASH);

  const appointmentDateLabel = getAppointmentDateLabel(nextAppointmentDate);

  useEffect(() => {
    if(!id) {
      const value = assignedBy ? true : false;
      if(!assignedBy) {
        setValue(TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor, true);
      }
      if(!isAssignedToEqualCurrentUser && assignId) {
        setValue(TO_DOS_FIELDS_NAMES.assignedBy, null);
      }
    }
  }, [assignedBy, id, assignId]);

  useEffect(() => {
    if(!id) {
      if(isAssignedToEqualCurrentUser) {
        setValue(TO_DOS_FIELDS_NAMES.createFUTask, false);
        resetField(TO_DOS_FIELDS_NAMES.reviewDate, { defaultValue: null });
      } else {
        setValue(TO_DOS_FIELDS_NAMES.createFUTask, true);
      }
    }
  }, [id, isAssignedToEqualCurrentUser]);

  useEffect(() => {
    if(!createFUTask && !id) {
      resetField(TO_DOS_FIELDS_NAMES.reviewDate, { defaultValue: null });
    }
  }, [createFUTask]);

  useEffect(() => {
    if(!createFUTaskForAssignerToMonitor && !id) {
      resetField(TO_DOS_FIELDS_NAMES.reviewDate, { defaultValue: null });
    }
  }, [createFUTaskForAssignerToMonitor]);

  useEffect(() => {
    if(!assignedBy && !id) {
      resetField(TO_DOS_FIELDS_NAMES.reviewDate, { defaultValue: null });
    }
  }, [assignedBy]);

  return (
    <FormBlock isBorderBottom={ false }>
      { isAssignedToEqualCurrentUser || !assignId ?
        <>
          <FormBlock.RowWrapper>
            <LeftColumnWrapper>
              <FormBlock.FormField
                $labelWidth={ LABEL_WIDTH.common }
                label={ 'Assigned by' }
              >
                <HFLinkedContact
                  AutocompleteProps={ {
                    ListboxProps: LISTBOX_PROPS
                  } }
                  FetchProps={ {
                    getParams: getNameParam,
                    requestSender: getActiveClientByName,
                    responseConverter: usersResponseConverter,
                  } }
                  UserItemProps={ {
                    disabled: isAssignedDetailsDisabled,
                  } }
                  control={ control }
                  disabled={ isAssignedDetailsDisabled }
                  initLinkedContact={ assignedByData }
                  name={ TO_DOS_FIELDS_NAMES.assignedBy }
                  withoutQueryMode={ true }
                />
              </FormBlock.FormField>
            </LeftColumnWrapper>

            { assignedBy && assignedBy !== createdByData?.id &&
              <RightColumnWrapper>
                <FormBlock.FormField
                  $labelWidth={ LABEL_WIDTH.taskForAssigner }
                  label={ 'Create a Follow-up Task for assigner to monitor' }
                >
                  <HFToggle
                    control={ control }
                    disabled={ isAssignedDetailsDisabled }
                    labelForFalse={ 'No' }
                    labelForTrue={ 'Yes' }
                    name={ TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor }
                  />
                </FormBlock.FormField>
              </RightColumnWrapper>
            }
          </FormBlock.RowWrapper>
          { assignedBy && assignedBy !== createdByData?.id &&
            <FormBlock.RowWrapper>
              <LeftColumnWrapper/>
              <RightColumnWrapper>
                <FormBlock.FormField
                  $labelWidth={ LABEL_WIDTH.rightColumn }
                  label={ 'Review Date' }
                >
                  <DateWrapper>
                    <HFDatePicker
                      control={ control }
                      disabled={ isAssignedDetailsDisabled || disabledReviewDateToMonitor }
                      name={ TO_DOS_FIELDS_NAMES.reviewDate }
                    />
                  </DateWrapper>
                  <DateButtons disabled={ isAssignedDetailsDisabled || disabledReviewDateToMonitor }/>
                </FormBlock.FormField>
              </RightColumnWrapper>
            </FormBlock.RowWrapper>
          }
        </>:
        <>
          <FormBlock.RowWrapper>
            <FormBlock.FormField
              $labelHeight={ 50 }
              $labelWidth={ LABEL_WIDTH.common }
              label={ 'Assigned Task Instructions' }
            >
              <HFTextInput
                inputProps={ ASSIGNED_TASK_INSTRUCTIONS_INPUT_PROPS }
                TextFieldProps={ ASSIGNED_TASK_INSTRUCTIONS_TEXT_FIELD_PROPS }
                control={ control }
                name={ TO_DOS_FIELDS_NAMES.assignedTaskInstructions }
              />
            </FormBlock.FormField>
          </FormBlock.RowWrapper>

          <FormBlock.RowWrapper>
            <LeftColumnWrapper>
              <FormBlock.FormField
                $labelWidth={ LABEL_WIDTH.common }
                label={ 'Create F/U Task' }
              >
                <HFToggle
                  control={ control }
                  disabled={ isAssignedDetailsDisabled }
                  labelPlacement={ 'end' }
                  name={ TO_DOS_FIELDS_NAMES.createFUTask }
                />
              </FormBlock.FormField>
            </LeftColumnWrapper>

            <RightColumnWrapper>
              <FormBlock.FormField
                $labelWidth={ LABEL_WIDTH.rightColumn }
                label={ 'Review Date' }
              >
                <DateWrapper>
                  <HFDatePicker
                    control={ control }
                    disabled={ isAssignedDetailsDisabled || disabledReviewDate }
                    name={ TO_DOS_FIELDS_NAMES.reviewDate }
                  />
                </DateWrapper>
                <DateButtons disabled={ isAssignedDetailsDisabled || disabledReviewDate }/>
              </FormBlock.FormField>
            </RightColumnWrapper>
          </FormBlock.RowWrapper>
        </>
      }

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            label={ 'Created Date' }
          >
            <FormBlock.VerticalCenteredWrapper>
              <CreatedDateWrapper $disabled={ isAssignedDetailsDisabled }>
                {formattedCreationDate}
              </CreatedDateWrapper>
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.rightColumn }
            label={ appointmentDateLabel }
          >
            <FormBlock.VerticalCenteredWrapper>
              <NextApptDate
                date={ nextAppointmentDate }
                disabled={ isGlobalDisabled }
                fallBack={ '-' }
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
