import React, { useCallback, useEffect, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import TextWithoutSpaceReservation from '@components/TextWithoutSpaceReservation';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getContactsForEmployees } from '@services/api/common/common';
import { getSearchParamsWithName, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import {
  EMPLOYEE_RELATIONSHIP_OPTIONS,
  FIELDS_NAMES,
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH,
} from './data';
import { CONTACT_TYPES } from '@constants/common';
import {
  EMAIL_TYPE_OPTIONS,
  PHONE_TYPE_OPTIONS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/BusinessContentTabs/components/BusinessEmployees/data';

import { ContentWrapper, LeftColumnWrapper, NamesWrapper, RightColumnWrapper, LeftLabelWrapper } from './styles';

import { ContentProps } from './types';
import { Emails, Phones } from '@/shared/types/contactsData';
import { LinkedContact, LinkedContactIndividual } from '@/shared/types/linkedContact';

export const Content = ({
  currentContact,
  existingContact,
  setExistingContact
}: ContentProps) => {
  const { control, clearErrors, setValue } = useFormContext();

  const { append } = useFieldArray({
    control,
    name: 'contacts',
  });

  const [ firstName, lastName, employeeId, emailType, phoneType ] = useWatch({
    control,
    name: [
      FIELDS_NAMES.firstName,
      FIELDS_NAMES.lastName,
      FIELDS_NAMES.employeeId,
      FIELDS_NAMES.emailType,
      FIELDS_NAMES.phoneType,
    ]
  });

  useEffect(() => {
    if(firstName || lastName){
      clearErrors(FIELDS_NAMES.employeeId);
    }
  },[firstName, lastName, clearErrors]);

  useEffect(() => {
    if(employeeId){
      clearErrors(FIELDS_NAMES.firstName);
      clearErrors(FIELDS_NAMES.lastName);
    }
  },[employeeId, clearErrors]);

  const responseConverter = useCallback((response: AxiosResponse) => {
    const usersData = usersResponseConverter(response);
    return usersData.filter((option: LinkedContact) => option.type !== CONTACT_TYPES.BUSINESS);
  }, []);

  const linkedContactFetchProps = useMemo(() => {
    return {
      delay: 500,
      getParams: getSearchParamsWithName({ id: currentContact?.id }),
      requestSender: getContactsForEmployees,
      responseConverter,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);
  
  const onLinkedContactChange = useCallback((data: LinkedContactIndividual | null) => {
    setExistingContact(data);
    if(data && data.contacts.length > 0) {
      append(data.contacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setExistingContact]);

  useEffect(() => {
    const emailData = existingContact?.contacts?.find(contact => {
      return contact.fieldId === emailType;
    }) as Emails;
    const value = emailData ? emailData.value : null;
    setValue(FIELDS_NAMES.email, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailType, existingContact]);

  useEffect(() => {
    const phoneData = existingContact?.contacts?.find(contact => {
      return contact.fieldId === phoneType;
    }) as Phones;
    const value = phoneData ? phoneData.value : null;
    setValue(FIELDS_NAMES.phone, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneType, existingContact]);

  const isLinkedContactDisabled = firstName || lastName;
  const isNameFieldsDisabled = employeeId;

  return (
    <ContentWrapper>
      <FormBlock 
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Contact Details' }/>
        <FormBlock.RowWrapper>
          <HFHiddenInput
            control={ control }
            defaultValue={ null }
            name={ FIELDS_NAMES.id }
          />
          <FormBlock.FormField
            $isDisabled={ isLinkedContactDisabled }
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Link Existing Record' }
          >
            <HFLinkedContact
              FetchProps={ linkedContactFetchProps }
              UserItemProps={ {
                disabled: false,
              } }
              control={ control }
              disabled={ isLinkedContactDisabled }
              initLinkedContact={ existingContact }
              name={ FIELDS_NAMES.employeeId }
              onLinkedContactChange={ onLinkedContactChange }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <TextWithoutSpaceReservation
          $bottom={ 2 }
          $disabled={ isNameFieldsDisabled || isLinkedContactDisabled }
          text={ 'or' }
        />
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $isDisabled={ isNameFieldsDisabled }
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Add Related Contact' }
          >
            <NamesWrapper>
              <HFTextInput
                control={ control }
                disabled={ isNameFieldsDisabled }
                name={ FIELDS_NAMES.firstName  }
                placeholder={ 'Enter First Name' }
              />
              <HFTextInput
                control={ control }
                disabled={ isNameFieldsDisabled }
                name={ FIELDS_NAMES.lastName }
                placeholder={ 'Enter Last Name' }
              />
            </NamesWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
      <FormBlock 
        isBorderBottom={ false }
        marginBottom={ 20 }
      >
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              isRequired={ true }
              label={ 'Title' }
            >
              <HFTextInput
                control={ control }
                name={ FIELDS_NAMES.title }
                placeholder={ 'Enter Title' }
              />

            </FormBlock.FormField>
          </LeftColumnWrapper>
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              isRequired={ true }
              label={ 'Relationship' }
            >
              <HFSelect
                control={ control }
                name={ FIELDS_NAMES.relationshipType }
                options={ EMPLOYEE_RELATIONSHIP_OPTIONS }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ '' }
              renderLabelContent={ () => {
                return (
                  <LeftLabelWrapper>
                    <HFSelect
                      control={ control }
                      name={ FIELDS_NAMES.phoneType }
                      options={ PHONE_TYPE_OPTIONS }
                    />
                  </LeftLabelWrapper>
                );
              } }
            >
              <HFPhoneField
                control={ control }
                countryListWidth={ 230 }
                name={ FIELDS_NAMES.phone }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ '' }
              renderLabelContent={ () => {
                return (
                  <LeftLabelWrapper>
                    <HFSelect
                      control={ control }
                      name={ FIELDS_NAMES.emailType }
                      options={ EMAIL_TYPE_OPTIONS }
                    />
                  </LeftLabelWrapper>
                );
              } }
            >
              <HFTextInput
                control={ control }
                name={ FIELDS_NAMES.email }
                placeholder={ 'Enter Email Address' }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Key Contact' }
            >
              <HFToggle
                control={ control }
                labelPlacement={ 'end' }
                name={ FIELDS_NAMES.keyContact }
                valueInConverter={ yesNoToBoolean }
                valueOutConverter={ booleanToYesNo }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Primary Contact' }
            >
              <HFToggle
                control={ control }
                labelPlacement={ 'end' }
                name={ FIELDS_NAMES.primaryContact }
                valueInConverter={ yesNoToBoolean }
                valueOutConverter={ booleanToYesNo }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </ContentWrapper>
  );
};
