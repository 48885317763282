import React, { useMemo, useCallback } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Navigate } from 'react-big-calendar';

import { observer } from 'mobx-react';

import { useStore } from '@store';
import { MODAL_TYPE } from '@constants/modalTypes';
import { CALENDAR_VIEWS } from '@constants/common';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import {
  DayAndWeekEventWrapper,
  DayHeader,
  MonthDateHeader,
  MonthEventWrapper,
  ShowMoreBtn,
  ShowMorePopover,
  Toolbar,
  WeekDayViewTimeGutterHeader,
} from './components';
import { ActivityPopup } from '@/modules/ActivityPopup';
import { EditActivityConfirmPopup } from '@/modules/ActivityPopup/components';

import { getFormats, getCustomSlotProp } from './utils';
import {
  getTimeDataBasedOnView,
  convertSecondStringToMinutes,
  convertActivityData,
  getTimeData
} from '@/modules/ActivityPopup/utils';

import { LOCALIZER, SLOT_ACTIONS } from './data';

import { StyledCalendar } from './styles';


const DndCalendar = withDragAndDrop(StyledCalendar);

export const Calendar = observer(() => {
  const modalStore = useStore().ModalStore;
  const calendarStore = useStore().CalendarStore;
  const { summerAndWinterTime } = useStore().SettingsStore;

  const { settings: {
    activityDefaultTime,
    calendarStartTime,
    calendarEndTime
  } } = useStore().SettingsStore;
  const {
    currentView,
    date,
    events,
    setShowMoreAnchorElem,
    setShowMoreEvents
  } = calendarStore;

  const slotPropGetter = useCallback((date) => (
    getCustomSlotProp(date, calendarStartTime, calendarEndTime)
  ), [calendarStartTime, calendarEndTime]);

  const formats = useMemo(getFormats, []);

  const showMoreComp = useCallback((count) => (
    <ShowMoreBtn count={ count } onClick={ setShowMoreAnchorElem } />
  ), [setShowMoreAnchorElem]);

  const openActivityPopup = (modalProps = {}) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        ...modalProps,
        onConfirm: calendarStore.saveOrUpdateActivity,
        onDelete: calendarStore.deleteActivity
      },
      component: ActivityPopup
    });
  };

  const scrollToTime = useMemo(() => {
    const d = date || new Date();
    const hoursString = calendarStartTime?.split(':')?.[0] || '00';

    return d.setHours(hoursString, [0],[0],[0]);
  }, [date]);

  const onSelectSlot = useCallback((data) => {
    const { action, start, end } = data;
    if(action === SLOT_ACTIONS.doubleClick) {
      openActivityPopup({
        initData: getTimeDataBasedOnView({
          view: currentView,
          startDateObj: start,
          endDateObj: end,
          duration: convertSecondStringToMinutes(activityDefaultTime) // TODO convert it at normalizer
        })
      });
    }
  }, [modalStore, currentView]);

  const onSelectEvent= useCallback((data) => {
    const { id, forDay, isEditable } = data.resource;

    if(isEditable){
      openActivityPopup({
        editMode: true,
        forDay,
        id,
      });
    }
  }, [modalStore]);


  const onNavigate = useCallback(async (date, view, action) => {
    // here we can get date, type(month, week, day), action (next, prev, today)
    if(action === Navigate.DATE) {
      return;
    }

    calendarStore.setDate(date);
    calendarStore.setUserSelectedDate(date);
  }, []);

  const changeActivity = (data) => {
    const { start, end, event: { resource, allDay } } = data;
    const newDateDate = {
      ...resource,
      ...getTimeData(start, end, allDay)
    };
    const convertedData  = convertActivityData(newDateDate, allDay, summerAndWinterTime);
    const formData =  {
      ...convertedData,
      withRecurring: false,
      recurringChangeDate: resource?.forDay,
    };

    if(resource?.recurring){
      modalStore.openModal({
        modalType: MODAL_TYPE.EDIT_ACTIVITY_CONFIRMATION,
        modalProps: {
          formData,
          onConfirm: calendarStore.saveOrUpdateActivity
        },
        component: EditActivityConfirmPopup
      });
    } else {
      calendarStore.saveOrUpdateActivity(formData);
    }
  };

  const onDrop = useCallback((data) => {
    const { isAllDay, event: { resource: { allDay } } } = data;
    const isDropFromAllDayToTimeGrid = !isAllDay && allDay;
    const isNotMonth = currentView === CALENDAR_VIEWS.day || currentView === CALENDAR_VIEWS.week;
    if(isDropFromAllDayToTimeGrid && isNotMonth){
      return;
    }
    changeActivity(data);
  }, [modalStore, currentView]);

  const onResize = useCallback(changeActivity, [modalStore]);

  return (
    <DndProvider backend={ HTML5Backend }>
      <DndCalendar
        $currentView={ currentView }
        components={ {
          toolbar: props => (
            <Toolbar { ...props }/>
          ),
          timeGutterHeader: WeekDayViewTimeGutterHeader,
          day: {
            event:  DayAndWeekEventWrapper,
          },
          week: {
            header: DayHeader,
            event: DayAndWeekEventWrapper,
          },
          month: {
            header: DayHeader,
            dateHeader: MonthDateHeader,
            event: MonthEventWrapper
          }        
        } }
        date={ date }
        defaultView={ currentView }
        draggableAccessor={ (event) => event?.resource?.isEditable }
        events={ events }
        formats={ formats }
        localizer={ LOCALIZER }
        messages={ {
          showMore: showMoreComp
        } }
        onEventDrop={ onDrop }
        onEventResize={ onResize }
        onNavigate={ onNavigate }
        onSelectEvent={ onSelectEvent }
        onSelectSlot={ onSelectSlot }
        onShowMore={ setShowMoreEvents }
        resizable={ true }
        scrollToTime={ scrollToTime }
        selectable={ true }
        slotPropGetter={ slotPropGetter }
        step={ 15 }
        timeslots={ 2 }
        tooltipAccessor={ () => '' }
        view={ currentView }
      />
      <ShowMorePopover />
    </DndProvider>
  );
});
