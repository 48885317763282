import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import TextField from '@material-ui/core/TextField';

export const StyledTextField = styled(TextField)`
  min-width: 500px;
  
  & .MuiInputBase-root{
    position: relative;
    border-radius: 20px;
    height: 42px;
    background-color: ${COLORS.white};
    font-size: 16px;
    padding-left: 16px !important;
  }

  & .MuiInputBase-input::placeholder {
    color: ${COLORS.grey};
  }

  /* ignore LastPass extention, customer issue */
  & div[data-lastpass-icon-root],
  & div[data-lastpass-root] {
    display: none;
  }
`;
