import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable, pausePersisting, startPersisting } from 'mobx-persist-store';

import { login, logout } from '@services/api/auth/auth';

import { Store } from '../store';

import { AUTH_WORKER_MESSAGE_TYPES } from '@constants/sharedWorker';

import {
  Token,
  UserAuthSuccessResponse,
  UserAuthType,
} from './types';

const sharedWorker = new SharedWorker(new URL('./sharedWorker.ts', import.meta.url));

class AuthStore {
  coreStore: Store;
  user: UserAuthType | null = null;
  token: Token | null = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {});

    makePersistable(this, {
      name: 'auth',
      properties: ['user', 'token'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.coreStore = coreStore;
    sharedWorker.port.addEventListener('message', async () => {
      await this.workerLogout();
    });
    sharedWorker.port.start();
  }

  setAuthedUser(data: UserAuthSuccessResponse){
    this.user = data.data.user;
    this.token = data.data.token;
  }

  setUserData(data: UserAuthType) {
    this.user = data;
  }

  async logout() {
    sharedWorker.port.postMessage({ type: AUTH_WORKER_MESSAGE_TYPES.logout });
  }

  async workerLogout() {
    await logout();

    await this.clear();
    await this.coreStore.SettingsStore.clear();
    await this.coreStore.SessionStore.endSession();
    this.coreStore.NotificationsStore.closeAll();
    sharedWorker.port.postMessage({ type: AUTH_WORKER_MESSAGE_TYPES.update });
  }

  async login(email: string, password: string) {
    const response = await login({ email, password });
    this.setAuthedUser(response.data);

    this.coreStore.SettingsStore.setGlobalFilters(response.data.data.configSettings.filters);
    // to open websocket connection for view five last opened contacts
    this.coreStore.AppStore.setWebsocketData(response.data.data.configSettings.clientChanel);
    this.coreStore.AppStore.setLastViewedContactsList(response.data.data.lastFiveContacts);
    this.coreStore.TrashStore.setTrashStorageDays(response.data.data.configSettings.trashStorageDays);
    this.coreStore.SettingsStore.setSummerAndWinterTime(response.data.data.configSettings.timezoneTimeChanged);
    this.coreStore.AppStore.init();
  }

  async clear() {
    pausePersisting(this);
    this.user = {} as UserAuthType;
    this.token = null;
    startPersisting(this);
    await clearPersistedStore(this);
  }
}

export default AuthStore;
