import React, { useCallback, useEffect } from 'react';

import { useStore } from '@services/store/store';

import { PreTablePanelWrapper, TodosTable } from './components';
import { TodoDeleteConfirmationPopup } from '@modules/TodoPopup/components';
import { TodoPopup } from '@modules/TodoPopup';
import Surface from '@components/BasicLayoutComponents/Surface';

import { ContactDetailsToDosProps } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { MODAL_TYPE } from '@constants/modalTypes';

export const ContactDetailsToDos = ({
  contactId
}: ContactDetailsToDosProps) => {

  const contactDetailsTodosStore = useStore().ContactDetailsTodosStore;
  const modalStore = useStore().ModalStore;
  const contactDetailsStore = useStore().ContactDetailsStore;
  const { currentContact } = contactDetailsStore;

  const addTodoHandler = useCallback((id?: IdType, isCloneTask?: boolean, previousCopyData?: any) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        isCloneTask,
        previousCopyData,
        initLinkedContact: {
          //@ts-ignore TODO: unify Linked Item data
          contactData: currentContact,
          //@ts-ignore
          linkedContactType: LINKED_ITEM_TYPES.contact,
          id: currentContact!.id
        },
        saveCallback: contactDetailsTodosStore.onSave,
        deleteCallback: contactDetailsTodosStore.onDelete,
        updateCallback: contactDetailsTodosStore.getTodos
      },
      component: TodoPopup
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalStore, currentContact, contactDetailsTodosStore.onSave, contactDetailsTodosStore.onDelete]);

  const deleteTodoHandler = useCallback((id: IdType, isRequirring: boolean) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_DELETE_CONFIRMATION,
      modalProps: {
        id,
        isRequirring,
        onConfirm: contactDetailsTodosStore.onDelete
      },
      component: TodoDeleteConfirmationPopup
    });
  }, [contactDetailsTodosStore.onDelete, modalStore]);

  useEffect(() => {
    contactDetailsTodosStore.init(contactId);
    return () => {
      contactDetailsTodosStore.reset();
    };
  }, [ contactDetailsTodosStore, contactId ]);

  return (
    <Surface>
      <Surface.Content>
        <Surface.Main>
          <PreTablePanelWrapper
            onAddTodoClick={ addTodoHandler }
          />
          <TodosTable
            onAddTodo={ addTodoHandler }
            onDeleteTodo={ deleteTodoHandler }
          />
        </Surface.Main>
      </Surface.Content>
    </Surface>
  );
};
