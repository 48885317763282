import ApiService from '@services/api/api.service';

import {
  AddContactsToListData,
  CheckFolderParams,
  CloneListParams,
  DeleteContactsFromListData,
  DeleteFolderParams,
  DeleteListContactsByTypeParams,
  DeleteListsParams,
  DisconnectMailChimpParams,
  ExportData,
  GetCriteriaParams,
  ListContactsByTypeParams,
  MultiFolderSaveParams,
  SaveFolderData,
  SaveListData,
  SaveToParentParams,
  SyncMailChimpParams
} from '@/shared/types/lists';
import { UpdateFlagsParams } from '@/shared/types/commonTypes';

export const getLists = (params?: any) => {
  return ApiService.get('/api/list/gridList', { params });
};

export const saveFolder = (data: SaveFolderData) => {
  return ApiService.post('/api/list/saveFolder', data);
};

export const saveList = (data: SaveListData) => {
  return ApiService.post(
    '/api/list/saveList',
    data,
    {
      timeout: 60000,
    }
  );
};

export const getListContacts = (params?: any) => {
  return ApiService.get('/api/list/listContactsGrid', { params });
};

export const addContactsToList = (data: AddContactsToListData) => {
  return ApiService.post('/api/list/addContactToList', data);
};

export const getContactsForAddToList = (params?: any) => {
  return ApiService.get('/api/crm/contact/searchContactToList', { params });
};

export const deleteContactsFromList = (params: DeleteContactsFromListData) => {
  return ApiService.delete('/api/list/deleteContactsFromList', { params });
};

export const getListSetup = (params?: any) => {
  return ApiService.get('/api/list/getListSetup', { params });
};

export const getListContactsByType = (params: ListContactsByTypeParams) => {
  return ApiService.get('/api/list/getContactsFromListByType', { params });
};

export const deleteContactsFromListByGroupType = (params: DeleteListContactsByTypeParams) => {
  return ApiService.delete('/api/list/deleteContactsFromListByType', { params });
};

export const deleteLists = (params: DeleteListsParams) => {
  return ApiService.delete('/api/list/delete', { params });
};

export const getFoldersTree = () => {
  return ApiService.get('/api/list/listFolders');
};

export const getExportContacts = (params: ExportData, timeout: number) => {
  return ApiService.get('/api/list/exportList', {
    responseType: 'blob',
    timeout,
    params
  });
};

export const getListCriteria = (params: GetCriteriaParams) => {
  return ApiService.get('/api/list/getListCriteriaById', { params });
};

export const deleteFolderWithMoveChildren = (params: DeleteFolderParams) => {
  return ApiService.delete('/api/list/deleteFolderWithMoveChildren', { params });
};

export const checkFolder = (params: CheckFolderParams) => {
  return ApiService.get('/api/list/checkEmptyFolder', { params });
};

export const cloneList = (params: CloneListParams) => {
  return ApiService.post('/api/list/cloneList', params);
};

export const updateListFlags = (params: UpdateFlagsParams) => {
  return ApiService.post('/api/list/updateFlags', params);
};

export const saveToParent = (params: SaveToParentParams) => {
  return ApiService.post('/api/list/saveToParent', params);
};

export const saveMultiFolder = (params: MultiFolderSaveParams) => {
  return ApiService.post('/api/list/crateMultiFolders', params);
};

export const syncMailChimp = (params: SyncMailChimpParams) => {
  return ApiService.post('/api/mailchimp/sync', params);
};

export const disconnectMailChimp = (params: DisconnectMailChimpParams) => {
  return ApiService.patch('/api/mailchimp/disconnect', params);
};

export const getMailchimpLists = (params: DisconnectMailChimpParams) => {
  return ApiService.get('/api/mailchimp/getLists', { params });
};
