import styled, { css } from 'styled-components';
import { NotesWrapperProps } from '../../types';

export const Wrapper =  styled.div<NotesWrapperProps>`
  position: relative;

  ${({ $padding }) => $padding && css`
    padding: ${$padding};
    box-sizing: border-box;
  `}
  
  flex-grow: 1;
  
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const Spacer = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  z-index: 1000;
`;
