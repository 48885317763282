import { format, parse } from 'date-fns';

import { convertToTwelveFormat } from '@/shared/utils/timeConverter';
import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { getConvertedStartEndDatesToDST } from '@/shared/utils/getConvertedStartEndDatesToDST';
import { getMeridian } from './utils';
import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { HOURS_MINUTES_SECONDS, MONTH_DAY_YEAR_BACKSLASH, YEAR_MONTH_DAY } from '@constants/dateFormats';

import {
  ContactActivity,
  ContactActivityData,
  ContactActivityFiltersNames,
  FilterData,
  FilterDataConverter
} from './types';
import { IdType, Timezone } from '@/shared/types/commonTypes';


const itemConverter = (item: ContactActivity, timezone: Timezone): ContactActivity => {
  const startDateAndTime = {
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime
  };

  if(!yesNoToBoolean(item.allDay)) {
    const convertedData = convertUTCFormatToStartEndDateString({
      ...startDateAndTime,
      isAllDay: yesNoToBoolean(item.allDay)
    });
  
    const convertedStartEndDatesToDST = getConvertedStartEndDatesToDST(convertedData, timezone);

    startDateAndTime.startDate = convertedStartEndDatesToDST.startDate;
    startDateAndTime.startTime = convertedStartEndDatesToDST.startTime;
    startDateAndTime.endDate = convertedStartEndDatesToDST.endDate;
    startDateAndTime.endTime = convertedStartEndDatesToDST.endTime;
  }
  const formattedStartDate = format(
    parse(
      `${startDateAndTime.startDate} ${startDateAndTime.startTime}`,
      `${YEAR_MONTH_DAY} ${HOURS_MINUTES_SECONDS}`,
      new Date()
    ),
    MONTH_DAY_YEAR_BACKSLASH
  );
  // eslint-disable-next-line max-len
  const formattedStartTime = `${convertToTwelveFormat(startDateAndTime.startTime, false)} ${getMeridian(startDateAndTime.startTime)}`;

  return ({
    ...item,
    recurring: yesNoToBoolean(item.recurring),
    startDate: formattedStartDate,
    startTime: formattedStartTime
  });
};

export const itemsNormalizer = (
  response: ContactActivityData,
  currentUserId: IdType,
  timezone: Timezone
): {
  filterData: FilterDataConverter,
  items: Array<ContactActivity>,
} => {
  const { filterData, ...activitiesItems } = response;

  const items = Object.values(activitiesItems) as Array<ContactActivity>;

  return {
    filterData: filterDataConverter(filterData, currentUserId),
    items: items.map((item) => itemConverter(item, timezone)),
  };
};

const filterDataConverter = (
  filterData: FilterData, currentUserId: IdType
): FilterDataConverter => {
  if (!filterData) {
    return {} as any;
  }

  return {
    [ContactActivityFiltersNames.Clients]: getSortedUserOption(filterData.clients, currentUserId),
  };
};
