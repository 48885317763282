import React from 'react';
import { observer } from 'mobx-react';

import { NoPermissionsForOpenContact } from '@/assets';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';

import { Image, MarkedText, NoPermissionWrapper, TextWrapper } from './styles';

import { NoViewPermissionProps } from './types';

export const NoViewPermission = observer(({
  onGoBackClick
}: NoViewPermissionProps) => {

  return (
    <NoPermissionWrapper>
      <Image src={ NoPermissionsForOpenContact }/>
      <TextWrapper>
        <MarkedText>No permissions </MarkedText>
        to see the contact. Please,
        <MarkedText> turn to your administrator</MarkedText>
      </TextWrapper>
      <BlockButton
        colorSchema={ COLOR_SCHEME_TYPES.contained }
        onClick={ onGoBackClick }
      >
        Go Back
      </BlockButton>
    </NoPermissionWrapper>
  );
});
