import axios from 'axios';
import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';

import { Store } from '@store';
import CommonTableStore from '@services/store/commonTableStore';
import { getActivitiesList, updateActivityFlags } from '@services/api/calendar/calendar';
import {
  deleteActivityToTrash,
  getActivityCreationSettings,
  saveActivity,
  updateActivity
} from '@services/api/addAndEditActivity/addAndEditActivity';

import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import {
  getMultipleSortParams,
  getFilterParams,
  getGlobalFlagged,
  setWhereNameAndOrderNameFilterParams
} from '@/shared/utils/filterUtils';
import { getAllStatusFilter, getInitStatusFilter, getRange } from './utils';
import { itemsNormalizer } from './normalizers';
import {
  activityCreationSettingsNormalizer
} from '@/modules/ActivityPopup/store/normalizer/activityCreationSettingsNormalizer';

import {
  ActivityTypeFilter,
  CalendarPeriod,
  ContactActivity,
  ContactActivityFiltersNames,
  FilterDataConverter,
  Filters,
  GridResponse
} from './types';
import { ActivityCreationSettings, ActivityPopupSettingsResponse } from '@/modules/ActivityPopup/store/types';
import { ActivityStatusOptions } from '@/shared/types/activityPopup';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { ContactItem } from '@/shared/types/contact';
import { ENTITY_NAMES } from '@constants/common';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';

export class ContactDetailsActivitiesStore {
  activityPopupSettings: ActivityCreationSettings = {} as ActivityCreationSettings;
  asyncRequestExecutor: AsyncRequestExecutor;
  contact: ContactItem | null = null;
  coreStore: Store;
  end: string = '';
  filterData: FilterDataConverter = {} as FilterDataConverter;
  filters: Filters = {} as Filters;
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onFilterReaction: IReactionDisposer;
  onPeriodChangeReaction: IReactionDisposer;
  onTypeChangeReaction: IReactionDisposer;
  period: CalendarPeriod = CalendarPeriod.all;
  previousActivityToggleState: boolean = false;
  start: string = '';
  table: CommonTableStore<ContactActivity>;
  typeFilter: ActivityTypeFilter | null = null;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      deleteActivity: flow.bound,
      getActivities: flow,
      getSetting: flow,
      init: flow.bound,
      reset: action.bound,
      saveOrUpdateActivity: flow.bound,
      saveOrUpdateActivityWithoutLoad: flow.bound,
      updateFlags: flow
    });
    this.coreStore = coreStore;
    this.table = new CommonTableStore<ContactActivity>({
      onGlobalFlaggedChangeReactionCallback: () => this.getActivities(this.contact?.id),
      onPageChangeReactionCallback: () => this.getActivities(this.contact?.id),
      onSortReactionCallback: () => this.getActivities(this.contact?.id),
    });

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.activity
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.onFilterReaction = this.createOnFilterReaction();
    this.onPeriodChangeReaction = this.createOnPeriodChangeReaction();
    this.onTypeChangeReaction = this.createOnTypeFilterChangeReaction();
  }

  *saveOrUpdateActivity(data: any){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.saveOrUpdateActivityWithoutLoad(data);

      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveOrUpdateActivityWithoutLoad(data: any) { // TODO type
    if(data?.id) {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => updateActivity(data),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success })
      });
    } else {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveActivity(data),
        onError: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success })
      });
    }
  }

  *deleteActivity(data: any){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteActivityToTrash(data),
        onError: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  createOnFilterReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive) {
          this.table.setCurrentPageWithoutReaction(1);
          this.getActivities(this.contact?.id);
        }
      }, 1500)
    );
  }

  createOnPeriodChangeReaction() {
    return reaction(
      () => this.period,
      () => {
        this.table.setCurrentPageWithoutReaction(1);
        this.getActivities(this.contact?.id);
      },
    );
  }

  createOnTypeFilterChangeReaction(){
    return reaction(
      () => this.typeFilter,
      () => {
        if(this.isPageActive) {
          this.table.setCurrentPageWithoutReaction(1);
          this.getActivities(this.contact?.id);  
        }
      }
    );
  }

  async *init(contactId: IdType){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    this.isPageActive = true;

    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData: ContactItem = yield await contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }

      yield this.getSetting();

      this.setFiltersFromServer();

      yield this.getActivities(contactId);

    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.coreStore.ContactDetailsStore.setPermission(false);
      }
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.contactProfileActivities]
    ))?.value;

    this.onFilterReaction();
    this.onPeriodChangeReaction();
    this.onTypeChangeReaction();
    this.table.onPageChangeReaction();
    this.table.onGlobalFlaggedChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);

      this.filters = {
        [ContactActivityFiltersNames.Clients]: serverFilterValue.filters?.where?.clients,
        [ContactActivityFiltersNames.StatusId]: getInitStatusFilter(this.activityPopupSettings.statuses)
      };
      this.typeFilter = {
        [ContactActivityFiltersNames.Type]: serverFilterValue.filters?.where?.type?.[0] || null
      };

      if(serverFilterValue.customparams) {
        const customparams = JSON.parse(serverFilterValue?.customparams);
        const showPrevious = customparams?.showPrevious;
        this.filters = {
          ...this.filters,
          [ContactActivityFiltersNames.StatusId]: showPrevious ?
            getAllStatusFilter(this.activityPopupSettings.statuses) :
            getInitStatusFilter(this.activityPopupSettings.statuses)
        };
        const period = customparams?.period || CalendarPeriod.all;
        this.setPeriod(period);
      } else {
        this.setPeriod(CalendarPeriod.all);
      }

      this.table.setGlobalFlaggedFilters(Boolean(serverFilterValue.filters?.where?.flagged?.[0]) || false);

      this.table.multipleSorting = serverFilterValue.filters?.order || {};

      const completedStatusId = this.activityPopupSettings?.statuses?.find((status) => {
        return status.label === ActivityStatusOptions.Completed;
      })?.value;
      const toggleState = this.filters?.statusId?.some((id: any) => {
        return Number(id) === Number(completedStatusId);
      }) || false;

      this.setPreviousActivityToggleState(toggleState);
    } else {
      this.setPeriod(CalendarPeriod.all);
      this.setInitStatusFilter();
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onGlobalFlaggedChangeReaction = this.table.createOnGlobalFlaggedChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
    this.onFilterReaction = this.createOnFilterReaction();
    this.onPeriodChangeReaction = this.createOnPeriodChangeReaction();
    this.onTypeChangeReaction = this.createOnTypeFilterChangeReaction();
  }

  *getSetting() {
    try {
      const activityPopupSettingsResp: ActivityPopupSettingsResponse = yield getActivityCreationSettings();
      this.activityPopupSettings = activityCreationSettingsNormalizer(activityPopupSettingsResp.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  *getActivities(contactId?: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    try {
      const start = async () => {
        this.table.clearItems(true);

        const currentContactId = contactId ? contactId : contactDetailsStore.currentContact!.id;
        contactDetailsStore.toggleLoadState(true);


        const response: GridResponse = await getActivitiesList({
          primaryContactId: currentContactId,
          start: this.start,
          end: this.end,
          page: this.table.currentPage,
          ...getFilterParams(this.filters),
          ...getFilterParams(this.typeFilter),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
          customparams: [{
            period: this.period,
            showPrevious: this.previousActivityToggleState
          }],
        });

        this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.contactProfileActivities], {
          page: this.table.currentPage,
          start: this.start,
          end: this.end,
          ...setWhereNameAndOrderNameFilterParams({
            whereFilters: {
              ...this.filters,
              [ContactActivityFiltersNames.Type]: [this.typeFilter?.type],
              flagged: [Number(this.table.globalFlagged)]
            },
            orderFilters: this.table.multipleSorting
          }),
          customparams: [JSON.stringify({
            period: this.period
          })],
        });

        const currentUserId = this.coreStore.SettingsStore.profile.id;
        const timezone = this.coreStore.SettingsStore.summerAndWinterTime;
        const normalized = itemsNormalizer(response.data.data.data, currentUserId, timezone);

        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.table.items = normalized.items;
        this.filterData = normalized.filterData;
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean) {
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield updateActivityFlags(params);

      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, { flagged: state });
      });
      if(this.table.globalFlagged) {
        this.getActivities(this.contact?.id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  setPeriod(period: CalendarPeriod) {
    this.period = period;
    const { start, end } = getRange(period);
    this.start = start;
    this.end = end;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setPreviousActivityToggleState(state: boolean) {
    this.previousActivityToggleState = state;
  }

  setAllStatusFilter() {
    this.setFilters({
      [ContactActivityFiltersNames.StatusId]: getAllStatusFilter(this.activityPopupSettings.statuses)
    });
  }

  setInitStatusFilter() {
    this.setFilters({
      [ContactActivityFiltersNames.StatusId]: getInitStatusFilter(this.activityPopupSettings.statuses)
    });
  }

  setTypeFilter(newType: ActivityTypeFilter) {
    this.typeFilter = newType;
  }

  reset() {
    this.onFilterReaction();
    this.onTypeChangeReaction();

    this.isPageActive = false;
    this.contact = null;
    this.filterData = {} as FilterDataConverter;
    this.filters = {};
    this.setInitStatusFilter();
    this.table.resetTable();
    this.previousActivityToggleState = false;
    this.typeFilter = null;

    this.onFilterReaction = this.createOnFilterReaction();
    this.onTypeChangeReaction = this.createOnTypeFilterChangeReaction();
  }
}
