import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { getNearestValue } from '@/shared/utils/getNearestValue';
import {
  CommonWrapper,
  InnerWrapper,
  OuterWrapper,
} from './styles';

export const InfiniteVerticalScrollLoop = (
  {
    children,
    divisor,
    initScrollPositionCorrector = 0,
    innerStyle,
    outerStyle,
    surroundingBackup = 2,
  }
) => {
  const contentRef = useRef(null);
  const scrollRef = useRef(null);
  const [height, setHeight] = useState(0);

  const backupHeight = height * surroundingBackup;
  let timerId = null;

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      clearTimeout(timerId);

      const scroll = scrollRef.current.scrollTop;
      if (scroll < backupHeight || scroll >= backupHeight + height) {
        scrollRef.current.scrollTop = backupHeight + (scroll % height);
      }

      const ceilScrollPosition = Math.ceil(scroll / divisor) * divisor;
      const floorScrollPosition = Math.floor(scroll / divisor) * divisor;
      const result = getNearestValue([ceilScrollPosition, floorScrollPosition], scroll);

      timerId = setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = result;
        }
      }, 100);
    }
  }, [height]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
      scrollRef.current.scrollTop = contentRef.current.offsetHeight * surroundingBackup + initScrollPositionCorrector;
    }
  }, []);

  return (
    <OuterWrapper style={ outerStyle }>
      <InnerWrapper
        onScroll={ handleScroll }
        ref={ scrollRef }
        style={ {
          height,
          ...innerStyle
        } }
      >
        {
          Array(surroundingBackup).fill().map((item, id) => (
            <CommonWrapper key={ id }>{ children }</CommonWrapper>
          ))
        }

        <CommonWrapper ref={ contentRef }>{ children }</CommonWrapper>

        {
          Array(surroundingBackup).fill().map((item, id) => (
            <CommonWrapper key={ id }>{ children }</CommonWrapper>
          ))
        }
      </InnerWrapper>
    </OuterWrapper>
  );
};
