import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { LINKED_ITEM_DATA_NAMES } from '@constants/linkedItem';
import { TO_DOS_FIELDS_NAMES, TO_DOS_LINKED_ITEMS_TYPES_SCHEME, TODOS_FILTER_NAMES } from '@constants/todosData';

import { IdType } from '@/shared/types/commonTypes';
import { TodoData, FilterData, FilterDataResponse } from './types';
import { TodoItem } from '@/shared/types/todos';

export const normalizer = (
  response: TodoData,
  currentUserId: IdType,
): {
  filterData: FilterData,
  items: Array<TodoItem>,
} => {
  const { filterData, ...todosItems } = response;

  const items = Object.values(todosItems) as Array<TodoItem>;

  return {
    filterData: filterDataConverter(filterData, currentUserId),
    items: itemsConverter(items),
  };
};

const itemsConverter = (items: Array<TodoItem>) => {
  if (!items) {
    return [];
  }

  return Object.entries(items).reduce((acc, [key, item]) => {
    if (isNaN(Number(key))) {
      return acc;
    }
    const convertedContactItem: TodoItem = todoConverter(item);

    acc.push(convertedContactItem);
    return acc;
  }, [] as Array<TodoItem>);
};

const todoConverter = (todo: TodoItem) => Object.entries(todo).reduce((acc, [name, value]) => {
  const isLinkedContact = Object.values(TO_DOS_LINKED_ITEMS_TYPES_SCHEME).some((linkedItemName) => {
    return linkedItemName.dataName === name;
  });

  if(isLinkedContact && value) {
    //@ts-ignore
    const data = name === LINKED_ITEM_DATA_NAMES.CONTACT_DATA ? value : value.contactData;
    acc[TO_DOS_FIELDS_NAMES.linkedItemData] = {
      ...data,
      //@ts-ignore
      linkedContactType: TO_DOS_LINKED_ITEMS_TYPES_SCHEME[name].type
    };
    return acc;
  }
  //@ts-ignore
  acc[name] = value;
  return acc;
}, {} as TodoItem);

const filterDataConverter = (
  filterData: FilterDataResponse, currentUserId: IdType
): FilterData => {
  if (!filterData) {
    return {} as FilterData;
  }

  return {
    [TODOS_FILTER_NAMES.users]: getSortedUserOption(filterData.clients, currentUserId),
    [TODOS_FILTER_NAMES.category]: optionsFromValue(filterData.category),
    [TODOS_FILTER_NAMES.stage]: filterData.stage,
  };
};
