import { addMinutes, addWeeks, format, getHours, getMinutes, parse, set } from 'date-fns';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { convertStartEndDateToUTCFormat } from '@/shared/utils/convertStartEndDateToUTCFormat';
import { getConvertedStartEndDatesToDST } from '@/shared/utils/getConvertedStartEndDatesToDST';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { CALENDAR_VIEWS } from '@constants/common';
import { YEAR_MONTH_DAY, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import { Client, IdType, Timezone } from '@/shared/types/commonTypes';
import { ClientConverter, ConvertActivityData, StartEndDateAndTime, TimeData } from '@/shared/types/activityPopup';

export const detectOutOfWorkHours = (data: ConvertActivityData, users: Array<ClientConverter>): boolean => {
  const { startTime, endTime } = data || {};
  if(!startTime || !endTime) {
    return false;
  }
  const [startHours] = startTime.split(':');
  const [endHours] = endTime.split(':');

  return users.some((user: ClientConverter) => {
    const { calendarEndTime, calendarStartTime } = user || {};
    if(!calendarEndTime && !calendarStartTime) {
      return false;
    }

    const [userStartHours] = calendarStartTime.split(':');
    const [userEndHours] = calendarEndTime.split(':');
    const isBeginOut = Number(startHours) < Number(userStartHours);
    const isEndOut = Number(endHours) > Number(userEndHours);

    return isBeginOut || isEndOut;
  });
};

export const getDateFromCurrWithOneWeek = (startDateString: string): string => {
  const start = parse(startDateString, YEAR_MONTH_DAY, new Date());
  const withOneWeekAdd = addWeeks(start, 1);
  return format(withOneWeekAdd, YEAR_MONTH_DAY);
};

export const convertSecondStringToMinutes = (secondsString: string) => Number(secondsString) / 60;

export const getTimeDataBasedOnView = ({ view, startDateObj, duration }: TimeData) => {
  if(view === CALENDAR_VIEWS.day || view === CALENDAR_VIEWS.week){
    // if open from button add activity we don't pass endDate
    const calculatedEndDateObj = addMinutes(startDateObj , duration);
    return getTimeData(startDateObj, calculatedEndDateObj);
  }

  if(view === CALENDAR_VIEWS.month){
    const now = new Date();
    const currHours = getHours(now);
    const currMinutes = getMinutes(now);
    const startOfStartDate = set(startDateObj, { hours: currHours, minutes: currMinutes });
    const newEndDateObj = addMinutes(startOfStartDate, duration);
    return getTimeData(startOfStartDate, newEndDateObj);
  }

  if(!view){
    const startDateObj = new Date();
    const endDateObj = addMinutes(startDateObj, duration);
    return getTimeData(startDateObj, endDateObj);
  }
};

export const getTimeData = (startDateObj: Date, endDateObj: Date, isAllDay?: string): StartEndDateAndTime => {
  const start = format(startDateObj, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
  const end = format(endDateObj, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
  const [startDate, startTime] = start.split(' ');
  const [endDate, endTime] = end.split(' ');

  if(isAllDay) {
    return {
      endDate,
      startDate,
    };
  }

  return {
    endDate,
    endTime,
    startDate,
    startTime,
  };
};

const FIELDS_TO_YES_NO = [
  'allDay',
  'recurring'
];

const TIMES_RANGE = [
  'startTime',
  'endTime'
];

const AMMOUNT_OF_ADDED_HOURS = -1;

export const convertActivityData = (
  data: ConvertActivityData,
  withoutTime: boolean,
  timezone: Timezone
): ConvertActivityData => {
  const convertData = (Object.entries(data).reduce((acc, item) => {
    const [name, value] = item as [string, unknown];
    // eslint-disable-next-line no-mixed-operators
    if(withoutTime && TIMES_RANGE.includes(name)) {
      return acc;
    }

    if(FIELDS_TO_YES_NO.includes(name)) {
      acc[name] = booleanToYesNo(value as boolean);
      return acc;
    }

    if(name === 'clients' && Array.isArray(value)) {
      acc[name] = value.map((item: Client & { userId: IdType }) => ({
        // if we update from popup we need use userId, because here we use useFieldArray from useForm
        // and we can't use id field because it reserved by useForm
        // but if update activity by drag and drop we use data from grid list with id
        id: item.userId || item.id
      }));
      return acc;
    }

    if(name === 'type') {
      acc[name] = typeConverter({ isBackend: false, type: value });
      return acc;
    }

    acc[name] = value;
    return acc;
  },{} as Record<string, unknown>)
  ) as ConvertActivityData;

  const convertedToUTCData = convertStartEndDateToUTCFormat({
    startDate: convertData.startDate,
    startTime: convertData.startTime,
    endDate: convertData.endDate,
    endTime: convertData.endTime,
    isAllDay: yesNoToBoolean(convertData.allDay)
  });

  const convertedStartEndDatesToDST = getConvertedStartEndDatesToDST(
    convertedToUTCData, timezone, AMMOUNT_OF_ADDED_HOURS
  );

  convertData.startDate = convertedStartEndDatesToDST.startDate;
  convertData.startTime = convertedStartEndDatesToDST.startTime;
  convertData.endDate = convertedStartEndDatesToDST.endDate;
  convertData.endTime = convertedStartEndDatesToDST.endTime;

  return convertData;
};
