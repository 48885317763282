import { ValueOf } from '@/shared/types/commonTypes';
import { ENTITY_NAMES } from '@constants/common';

import { generateMessage } from './generateMessage';

import NotificationsStore from '@services/store/notificationsStore';
import { Props, CreateUpdateProps, CustomProps } from './types';

import { NOTIFICATION_MESSAGE_ACTIONS, NOTIFICATION_TYPES } from '@constants/notifications';


export class NotificationHelper {
  notificationsStore: NotificationsStore;
  entity: ValueOf<typeof ENTITY_NAMES>;

  constructor(
    notificationsStore: NotificationsStore,
    entity: ValueOf<typeof ENTITY_NAMES>
  ) {
    this.notificationsStore = notificationsStore;
    this.entity = entity;
  }

  private getEntityName(entity?: string): string {
    return entity || this.entity;
  }

  private getAutoUniqueKey(isAutoUniqueKey?: boolean) {
    return isAutoUniqueKey
      ? Date.now() + '-' + Math.random() * 1000000
      : null;
  }
  private notification(
    action: ValueOf<typeof NOTIFICATION_MESSAGE_ACTIONS>,
    {
      autoHideDuration,
      countOfEntities,
      isAutoUniqueKey = true,
      otherEntityName,
      status,
      uniqueKey,
    }: Props
  ) {
    const calculatedEntityName = this.getEntityName(otherEntityName);
    const autoUniqueKey = this.getAutoUniqueKey(isAutoUniqueKey);

    this.notificationsStore.showNotification({
      autoHideDuration,
      key: `${status} ${action} ${calculatedEntityName} ${uniqueKey || autoUniqueKey || ''}`,
      message: generateMessage({
        action,
        countOfEntities,
        entity: calculatedEntityName,
        status,
      }),
      variant: status,
    });
  }

  customNotification(
    {
      autoHideDuration,
      customAction,
      isAutoUniqueKey = true,
      message,
      otherEntityName,
      status,
      uniqueKey,
    }: CustomProps
  ) {
    const calculatedEntityName = this.getEntityName(otherEntityName);
    const autoUniqueKey = this.getAutoUniqueKey(isAutoUniqueKey);

    this.notificationsStore.showNotification({
      autoHideDuration,
      key: `${status} ${customAction} ${calculatedEntityName} ${autoUniqueKey || uniqueKey || ''}`,
      message: message,
      variant: status,
    });
  }

  createUpdateNotification({
    autoHideDuration,
    countOfEntities,
    isError,
    isAutoUniqueKey,
    isUpdate,
    otherEntityName,
    uniqueKey,
  }: CreateUpdateProps){

    if(isUpdate){
      this.update({
        status: isError ? NOTIFICATION_TYPES.error : NOTIFICATION_TYPES.success,
        autoHideDuration,
        countOfEntities,
        otherEntityName,
        uniqueKey,
        isAutoUniqueKey
      });
    } else {
      this.create({
        status: isError ? NOTIFICATION_TYPES.error : NOTIFICATION_TYPES.success,
        autoHideDuration,
        countOfEntities,
        otherEntityName,
        uniqueKey,
        isAutoUniqueKey
      });
    }
  }

  delete(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.delete, props);
  }

  create(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.create, props);
  }

  update(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.update, props);
  }

  remove(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.remove, props);
  }

  load(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.load, props);
  }

  restore(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.restore, props);
  }

  download(props: Props){
    this.notification(NOTIFICATION_MESSAGE_ACTIONS.download, props);
  }
}
