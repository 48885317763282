import React, { useEffect, useMemo, useState } from 'react';
import { CountryIso2, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import { InputAdornment, Select as CountryList } from '@material-ui/core';

import { actualCountry } from './utils';
import { getInternationalFormatNumber } from '@modules/HookFormComponents/HFPhoneField/validation';

import CustomTextField from '@components/CustomTextField';
import TextWithTooltip from '../TextWithTooltip';

import {
  CountryCode,
  CountryListItem,
  INPUT_PROPS_STYLES,
  MENU_LIST_STYLES,
  MENU_STYLES,
  PhoneInputWrapper,
} from './styles';

import { ChangeDataArgs, PhoneInputProps } from './types';


export const PhoneInput = ({
  countryListWidth = 400,
  disabled,
  errorMessage,
  isError,
  onChange,
  value,
}: PhoneInputProps) => {

  const [editablePhoneNumber, setEditablePhoneNumber] = useState(value);

  const phoneNumber = useMemo(() => {
    return getInternationalFormatNumber(editablePhoneNumber);
  }, [editablePhoneNumber]);

  const { handlePhoneValueChange, country, setCountry } = usePhoneInput({
    countries: actualCountry,
    defaultCountry: 'us',
    disableDialCodeAndPrefix: true,
    onChange: (data: ChangeDataArgs) => {
      const { phone, country, inputValue } = data;
      onChange(phone, country);
      setEditablePhoneNumber(inputValue);
    },
    value,
  });

  useEffect(() => {
    const flagsTitle = document.querySelector('.react-international-phone-country-selector-button');
    if(flagsTitle) {
      flagsTitle.removeAttribute('title');
    }
  }, [ value ]);

  return (
    <PhoneInputWrapper>
      <CustomTextField
        disabled={ disabled }
        error={ isError }
        helperText={ isError && errorMessage }
        onChange={ handlePhoneValueChange }
        placeholder={ '(XXX) XXX-XXXX' }
        showErrorText={ true }
        value={ phoneNumber }
        InputProps={ {
          startAdornment: (
            <InputAdornment position='start'>
              <CountryList
                MenuProps={ {
                  style: {
                    ...MENU_STYLES,
                    width: countryListWidth
                  },
                  MenuListProps: MENU_LIST_STYLES,
                } }
                onChange={ (event) => setCountry(event.target.value as CountryIso2) }
                renderValue={ (value: any) => <FlagImage iso2={ value }/> }
                value={ country.iso2 }
              >
                {
                  actualCountry.map((country) => {
                    const countryData = parseCountry(country);
                    return (
                      <CountryListItem
                        key={ countryData.iso2 }
                        value={ countryData.iso2 }
                      >
                        <FlagImage
                          iso2={ countryData.iso2 }
                          size={ 17 }
                        />
                        <TextWithTooltip text={ countryData.name }/>
                        <CountryCode>+{ countryData.dialCode }</CountryCode>
                      </CountryListItem>
                    );
                  })
                }
              </CountryList>
            </InputAdornment>
          ),
          ...INPUT_PROPS_STYLES
        } }
      />
    </PhoneInputWrapper>
  );
};
